<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <template #header>
        <div class="cols col-12 d-flex flex-wrap">
          <filter-checkbox
            id="banned-users"
            class="w-auto m-1 ml-lg-0 mr-lg-auto"
            label="Banned users"
            :value="filter.is_banned"
            @change="setFilter({ is_banned: $event })"
            :disabled="filter.whitelisted"
          />
          <filter-checkbox
            id="inactivated-users"
            class="w-auto m-1 ml-lg-0 mr-lg-auto"
            label="Inactivated users"
            :value="filter.is_inactivated"
            @change="setFilter({ is_inactivated: $event })"
          />
          <filter-checkbox
            id="whitelisted-users"
            class="w-auto m-1 m-lg-auto"
            label="Whitelisted users"
            :value="filter.whitelisted"
            @change="setFilter({ whitelisted: $event })"
            :disabled="filter.is_banned"
          />
          <filter-checkbox
            id="auto-flagged"
            class="w-auto m-1 m-lg-auto"
            label="Auto flagged"
            :value="filter.auto_flagged"
            @change="setFilter({ auto_flagged: $event })"
          />
          <filter-checkbox
            id="verified"
            class="w-auto m-1 m-lg-auto"
            label="Verified"
            :value="filter.verified"
            @change="setFilter({ verified: $event })"
          />
          <filter-checkbox
            id="service-expire-soon"
            class="w-auto m-1 ml-lg-auto mr-lg-0"
            label="Service expire soon"
            :value="filter.soon_expired_service"
            @change="setFilter({ soon_expired_service: $event })"
          />
          <div class="d-flex m-1 ml-lg-auto mr-lg-0 w-auto" id="epg-expired">
            <b-form-checkbox
              v-model="filter.is_epg_xml_expired"
              @change="
                isEpgXmlExpired({
                  is_epg_xml_expired: $event,
                  service_status: filter.service_status,
                })
              "
            />
            <div>Is EPG XML expired</div>
          </div>
          <b-tooltip target="banned-users" variant="info" triggers="hover">
            List users that are banned
          </b-tooltip>
          <b-tooltip target="whitelisted-users" variant="info" triggers="hover">
            List users that are whitelisted
          </b-tooltip>
          <b-tooltip target="auto-flagged" variant="info" triggers="hover">
            List users that tried to reach the XML from a server
          </b-tooltip>
          <b-tooltip target="verified" variant="info" triggers="hover">
            List users that verified their email address
          </b-tooltip>
          <b-tooltip
            target="service-expire-soon"
            variant="info"
            triggers="hover"
          >
            List users whose service is expiring in 7 days
          </b-tooltip>
          <b-tooltip target="epg-expired" variant="info" triggers="hover">
            List users whose EPG XML hasn't been updated in the last 24 hours
          </b-tooltip>
        </div>
        <b-dropdown
          :text="
            $route.query.service_status
              ? $route.query.service_status.toUpperCase()
              : 'Select status'
          "
          :disabled="filter.is_epg_xml_expired"
          variant="outline-primary"
          class="cols col-sm-12 col-md-12 col-lg-3 mt-2"
        >
          <b-dropdown-item
            v-for="service_status in allStatus"
            :key="service_status"
            @click="setFilter({ service_status })"
          >
            {{ service_status.toUpperCase() }}
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click="setFilter({ service_status: undefined })">
            Reset to none
          </b-dropdown-item>
        </b-dropdown>
        <search-input @search="setFilter({ search: $event })"></search-input>
      </template>
      <table-spinner :loading="loading"></table-spinner>
      <b-table
        v-if="!loading"
        id="user-list"
        class="h-auto pt-2 pb-1"
        :items="data"
        responsive
        primary-key="id"
        :fields="fields"
        show-empty
        empty-text="No matching records found"
      >
        <template #head()="fields">
          <table-header-labels
            :fields="fields"
            :filter="filter"
            @onSort="changePage($event)"
          ></table-header-labels>
        </template>
        <template #cell(name)="data">
          <div vertical-align="center">
            <b-link
              :to="{ name: 'user', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <small class="text-muted">{{ data.item.email }}</small>
          </div>
        </template>
        <template #cell(is_banned)="data">
          <b-form-checkbox
            :disabled="authUser.id === data.item.id"
            :id="'user-banned' + data.item.id"
            :checked="data.value"
            v-model="data.item.is_banned"
            @change="onBan(data.item, $event)"
            :name="'user-banned' + data.item.id"
          />
        </template>
        <template #cell(is_inactivated)="data">
          <b-form-checkbox
            :disabled="authUser.id === data.item.id"
            :id="'user-inactivated' + data.item.id"
            :checked="data.value"
            v-model="data.item.is_inactivated"
            @change="onInactivate(data.item, $event)"
            :name="'user-inactivated' + data.item.id"
          />
        </template>
        <template #cell(email_verified_at)="data">
          <b-form-checkbox disabled :checked="data.item.is_email_verified" />
        </template>
        <template #cell(service_date)="data">
          <div class="text-nowrap">
            <span
              v-for="service in data.item.services"
              :key="service.id"
              class="d-flex"
            >
              <div :id="'tooltip-target' + data.item.id">
                <feather-icon
                  class="text-warning"
                  icon="ClockIcon"
                  size="20"
                  v-if="isExpiring(service.finish_at)"
                />
                {{ service.name }}
              </div>
              <feather-icon
                class="ml-1 text-warning"
                :id="'warning-tooltip' + data.item.id"
                icon="AlertTriangleIcon"
                size="20"
                v-if="!isUpdated(data.item.epg_xml_generated_at)"
              />
              <b-tooltip
                :target="'tooltip-target' + data.item.id"
                triggers="hover"
                class="tooltip"
                :variant="isExpiring(service.finish_at) ? 'warning' : 'success'"
              >
                Begin at: {{ $dateHelpers.formatDate(service.begin_at) }}
                <br />Finish at: {{ $dateHelpers.formatDate(service.finish_at)
                }}<br />
                {{ service.display_name }}: {{ service.quantity }}
              </b-tooltip>
            </span>
            <b-tooltip
              :target="'warning-tooltip' + data.item.id"
              triggers="hover"
              class="tooltip"
              variant="warning"
            >
              User's EPG XML was last updated at: <br />
              {{ $dateHelpers.formatDate(data.item.epg_xml_generated_at) }}
              {{ $dateHelpers.formatTime(data.item.epg_xml_generated_at) }}
            </b-tooltip>
          </div>
        </template>
        <!-- Column: Role -->
        <template #cell(is_admin)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.is_admin ? "Admin" : "Common user"
            }}</span>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-dropdown
            :id="'dropdown-actions-' + data.item.id"
            text="Actions"
            class="m-md-2 position-relative h-auto"
            variant="outline-primary"
          >
            <b-dropdown-item
              v-if="
                authUser.id !== data.item.id && !data.item.is_email_verified
              "
              @click="onEmailVerify(data.item, $event)"
              >Verify Email
            </b-dropdown-item>
            <b-dropdown-item
              v-if="authUser.id !== data.item.id"
              @click="onLoginAsUser(data.item, $event)"
              >Log in as
            </b-dropdown-item>

            <b-dropdown-item @click="onSendPasswordReset(data.item, $event)"
              >Send password reset email
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                authUser.id !== data.item.id && !data.item.is_email_verified
              "
              @click="onResendEmailVerify(data.item, $event)"
            >
              Resend Verify Email
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.services.length !== 0"
              @click="onEpgLinkUpdate(data.item, $event)"
              >Update EPG
            </b-dropdown-item>
            <b-link
              v-if="data.item.services.length !== 0"
              :href="data.item.epg_link"
              @click.prevent="copyLink(data.item.epg_link)"
              target="_blank"
              class="dropdown-item"
              >Copy EPG Xml Link
              <feather-icon size="15" icon="CopyIcon" />
            </b-link>
          </b-dropdown>
        </template>
      </b-table>
      <template #footer>
        <pagination
          :meta-data="meta"
          @change-page="changePage({ page: $event })"
          @change-per-page="setFilter({ per_page: $event })"
        >
        </pagination>
      </template>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BLink,
  BTable,
  BTooltip,
  BDropdownDivider,
} from "bootstrap-vue";
import { mapActions } from "vuex";
import {
  isBeforeExpirationThreshold,
  isDiffMoreThanOneDay,
} from "@/services/utils/util";
import Pagination from "@/layouts/components/XmlTvPagination.vue";
import debounce from "lodash.debounce";
import TableSpinner from "@/layouts/components/TableSpinner.vue";
import FilterCheckbox from "@/layouts/components/FilterCheckbox.vue";
import { mapBooleanQueryParameter } from "@/services/utils/util";
import SearchInput from "@/layouts/components/form/SearchInput.vue";
import TableHeaderLabels from "@/layouts/components/TableHeaderLabels.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import Vue from "vue";
import { onUserBanToggle, onUserInactivateToggle } from "@/services/utils/util";

export default {
  components: {
    BCard,
    BTable,
    BLink,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    Pagination,
    BTooltip,
    TableSpinner,
    FilterCheckbox,
    BDropdownDivider,
    SearchInput,
    TableHeaderLabels,
    FeatherIcon,
  },
  data() {
    return {
      fields: [
        { key: "name", label: "Name" },
        "email",
        { key: "is_admin", label: "Roles" },
        { key: "is_banned", label: "Ban" },
        { key: "ban_reason", label: "Ban reason" },
        { key: "is_inactivated", label: "Inactivate" },
        { key: "inactive_reason", label: "Inactivate reason" },
        { key: "email_verified_at", label: "Verified" },
        { key: "service_date", label: "Services" },
        { key: "actions", label: "" },
        "",
      ],
      loading: false,
      allStatus: ["active", "not-active", "expired"],
    };
  },
  computed: {
    filter() {
      return {
        ...this.$route.query,
        is_banned: mapBooleanQueryParameter(this.$route.query, "is_banned"),
        is_inactivated: mapBooleanQueryParameter(
          this.$route.query,
          "is_inactivated"
        ),
        whitelisted: mapBooleanQueryParameter(this.$route.query, "whitelisted"),
        auto_flagged: mapBooleanQueryParameter(
          this.$route.query,
          "auto_flagged"
        ),
        verified: mapBooleanQueryParameter(this.$route.query, "verified"),
        soon_expired_service: mapBooleanQueryParameter(
          this.$route.query,
          "soon_expired_service"
        ),
        is_epg_xml_expired: mapBooleanQueryParameter(
          this.$route.query,
          "is_epg_xml_expired"
        ),
        service_status: this.$route.query?.service_status ?? undefined,
        page: this.$route.query?.page ?? 1,
        per_page: this.$route.query?.per_page ?? 10,
        sort_by: this.$route.query?.sort_by ?? "email",
        order_by: this.$route.query?.order_by ?? "asc",
      };
    },
    authUser() {
      return this.$store.getters["auth/authUser"];
    },
    data() {
      return this.$store.getters["adminUser/getUserListData"];
    },
    meta() {
      return this.$store.getters["adminUser/getUserListMeta"];
    },
  },
  mounted() {
    this.getUsers({ ...this.filter }).catch(console.error);
  },
  methods: {
    ...mapActions({
      getUsers: "adminUser/getUsers",
      updateUsers: "adminUser/updateUsers",
      banUserToggle: "adminUser/banUserToggle",
      inactivateUserToggle: "adminUser/inactivateUserToggle",
      verifyUserEmail: "adminUser/verifyUserEmail",
      updateEpgLink: "adminUser/updateEpgLink",
      forgetPassword: "adminUser/forgetPassword",
      loginAs: "auth/loginAs",
      resendVerifyEmail: "adminUser/resendVerifyEmail",
    }),
    copyLink(link) {
      navigator.clipboard.writeText(link);
      Vue.$toast.info(`Copy link to clipboard: ${link}`);
    },
    isExpiring(date) {
      return isBeforeExpirationThreshold(date);
    },
    isUpdated(date) {
      return isDiffMoreThanOneDay(date);
    },
    changePage(filter = {}) {
      this.$router.replace({ query: { ...this.filter, ...filter } });
      this.onRefreshPage();
    },
    setFilter(filter = {}) {
      this.filter.page = 1;
      this.$router.replace({ query: { ...this.filter, ...filter } });

      if (filter.search !== undefined) {
        if (filter.search?.length === 0) {
          this.$router.replace({
            query: { ...this.filter, search: undefined },
          });
        }
      }

      this.onRefreshPage();
    },
    isEpgXmlExpired(filter = {}) {
      filter.is_epg_xml_expired
        ? (filter.service_status = "active")
        : (filter.service_status = undefined);

      this.filter.page = 1;
      this.$router.replace({ query: { ...this.filter, ...filter } });
      this.onRefreshPage();
    },
    onRefreshPage: debounce(async function () {
      this.loading = true;
      try {
        await this.getUsers(this.filter);
      } finally {
        this.loading = false;
      }
    }, 300),
    async onBan(user, checked) {
      try {
        await onUserBanToggle(checked, user, this.banUserToggle);
      } finally {
        await this.onRefreshPage();
      }
    },
    async onInactivate(user, checked) {
      try {
        await onUserInactivateToggle(checked, user, this.inactivateUserToggle);
      } finally {
        await this.onRefreshPage();
      }
    },
    async onLoginAsUser(user) {
      await this.loginAs(user);
    },
    async onEmailVerify(user) {
      try {
        await this.verifyUserEmail(user);
      } finally {
        await this.onRefreshPage();
      }
    },
    async onEpgLinkUpdate(user) {
      try {
        await this.updateEpgLink(user);
      } finally {
        await this.onRefreshPage();
      }
    },
    async onSendPasswordReset(user) {
      await this.forgetPassword(user);
    },
    async onResendEmailVerify(id) {
      await this.resendVerifyEmail(id);
    },
  },
};
</script>

<style>
.per-page-selector {
  width: 90px;
}
.expire-icon {
  width: 20px;
}
input[type="checkbox"]:disabled + .custom-control-label {
  white-space: pre-line !important;
  cursor: default;
}
</style>
